import { graphql } from 'gatsby';
import React from 'react';
import SanityImage from 'gatsby-plugin-sanity-image';
import BlockContent from '@sanity/block-content-to-react';
import getYouTubeID from 'get-youtube-id';
import YouTube from 'react-youtube';
import styled from 'styled-components';
import SEO from '../components/SEO';
import { EmbedStyles } from './Project';

import Gallery from '../components/Gallery';

const PostStyles = styled.div`
  .image-container {
    margin: 0 auto 5rem auto;
    height: 100%;
    @media (max-width: 800px) {
      display: block !important;
      width: 100% !important;
    }
  }

  .image-container img {
    /* object-fit: cover; */
    height: 95%;
    width: 95%;
    display: block;
    margin: auto;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title h1 {
    text-align: left;
  }

  .metadata {
    color: grey;
    font-weight: 300;
    display: flex;
    align-items: right;
    justify-content: right;
    gap: 1rem;
  }
  .metadata span {
    color: white;
  }

  .subtitle {
    margin-top: 2rem;
  }
`;

export default function SingleBlogPostPage({ data: { blogPost } }) {
  const date = new Date(blogPost.datePublished).toLocaleDateString('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const serializers = {
    types: {
      youtube: ({ node }) => {
        const { url } = node;
        const id = getYouTubeID(url);

        return (
          <EmbedStyles>
            <YouTube videoId={id} />
          </EmbedStyles>
        );
      },
      inlineImage: ({ node }) => (
        <div
          style={{
            display: `${node.display}`,
            width: `${node.width}%`,
            // height: `${node.height}px`,
          }}
          className="image-container"
        >
          <SanityImage {...node.image} className="shadow pixelated-load" />
        </div>
      ),

      gallery: ({ node }) => <Gallery images={node.images} />,
    },
  };

  return (
    <>
      <SEO title={blogPost.name} image={blogPost.image?.asset?.fluid?.src} />
      <PostStyles>
        <SanityImage
          {...blogPost.image}
          className="hero-img pixelated-load shadow rounded"
        />
        <div className="content">
          <div className="title">
            <h1>{blogPost.name}</h1>
            <div className="postInfo">
              <h5 className="metadata">
                <span title={blogPost.datePublished}>{date}</span>
              </h5>
            </div>
          </div>

          {blogPost.subtitle !== null ? (
            <h4 className="subtitle">{blogPost.subtitle}</h4>
          ) : (
            <></>
          )}

          <BlockContent
            blocks={blogPost._rawContent}
            serializers={serializers}
            projectId="o6ahhntv"
            dataset="production"
            className="block"
          />
        </div>
      </PostStyles>
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    blogPost: sanityBlogPost(slug: { current: { eq: $slug } }) {
      name
      subtitle
      id
      datePublished
      image {
        ...ImageWithPreview
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
        alt
      }
      _rawContent
      categories {
        name
        id
      }
    }
  }
`;
